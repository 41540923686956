/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/space-before-function-paren */
/* eslint-disable @typescript-eslint/indent */
import { createRef, useEffect, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import OrganizationActions from '../Store/Actions/OrganizationsActions'
import StateAndCityActions from '../Store/Actions/StateAndCityActions'
import ProfileActions from '../Store/Actions/ProfileActions'
import SuffixAndSpecialityActions from '../Store/Actions/SuffixAndSpecialtyActions'
import toastMessages from '../utils/helpers/toastMessage'
import DashboardActions from '../Store/Actions/DashboardActions'
import { FETCH_NOTIFICATIONS_SUCCESS, LOGOUT, UPDATE_NOTIFICATION_UNREAD_COUNT } from '../Store/Actions/types'
import PathLabActions from '../Store/Actions/PathLabActions'
import NotificationActions, { CustomNotificationOptions } from '../Store/Actions/NotificationsActions'
import SyncActions from '../Store/Actions/SyncActions'
import ActiveTestsActions from '../Store/Actions/ActiveTestsActions'
import TermsAndConditionsPopup from '../components/global/TermsAndConditionsPopup'
import UserNotificationActions from '../Store/Actions/UserNotificationActions'
import NotificationsListService from '../services/NotificationsListService'
import ApiNotificationData from '../models/notificationsModels/apiNotificationsData.model'
import useSocket from '../utils/customHooks/useSocket'
import useSocketSubscribe from '../utils/customHooks/useSocketSubscribe'
// import socket from '../server';
interface PrivateRouteProps {
    children?: any
    loadingProfile?: any
    loadingProfileError?: any
    loadingOrganization?: any
    loadingPathLabs?: any
    pathLabsError?: any
    pathLabs?: any
    fetchingSuffix?: any
    errorSuffix?: any
    fetchingSpeciality?: any
    errorSpeciality?: any
    specialityData?: any
    fetchingStateAndCity?: any
    errorStateAndCity?: any
    stateAndCityData?: any
    suffixData?: any
    organizationsError?: any
    organizations?: any
    getOrganizations?: any
    getPathLabUsers?: any
    getStateAndCity?: any
    getSuffix?: any
    getSpeciality?: any
    fetchProfile?: any
    profile?: any
    fetchSync?: any
    shouldFetch?: any
    syncError?: any
    syncSuccess?: any
    fetchActiveTests?: any
    fetchingActiveTests?: any
    fetchingUnreadCount?: any
    fetchingUnreadCountError?: any
    fetchingUnreadCountSuccess?: any
    fetchedUnReadCount?: any
    fetchingActiveTestsError?: any
    fetchNotificationMedium?: any
    fetchOrganizationNotificationMedium?: any
    fetchUserNotificationMedium?: any
    fetchUserNotificationType?: any
    fetchNotificationType?: any
    activeTests?: any
    socket?: any
    getUnreadCount?: any
}

function PrivateRoute({
    children,
    loadingProfile,
    loadingProfileError,
    loadingOrganization,
    loadingPathLabs,
    pathLabsError,
    pathLabs,
    fetchingSuffix,
    errorSuffix,
    fetchingSpeciality,
    errorSpeciality,
    specialityData,
    fetchingStateAndCity,
    errorStateAndCity,
    stateAndCityData,
    suffixData,
    organizationsError,
    organizations,
    getOrganizations,
    getPathLabUsers,
    getStateAndCity,
    getSuffix,
    getSpeciality,
    fetchProfile,
    profile,
    fetchSync,
    shouldFetch,
    syncError,
    syncSuccess,
    fetchActiveTests,
    fetchingActiveTests,
    fetchingUnreadCount,
    fetchingUnreadCountError,
    fetchingUnreadCountSuccess,
    fetchedUnReadCount,
    fetchingActiveTestsError,
    fetchNotificationMedium,
    fetchOrganizationNotificationMedium,
    fetchUserNotificationMedium,
    fetchUserNotificationType,
    fetchNotificationType,
    activeTests,
    socket,
    getUnreadCount
}: PrivateRouteProps): JSX.Element {
    const { authState, oktaAuth }: any = useOktaAuth()
    let getRole = authState?.accessToken?.claims?.Profile?.roles || profile?.roles
    const { sessionDestroyed } = useSelector((state: any) => state.Session)
    const { unReadCount, notificationsData } = useSelector((state: any) => state.UserNotifications)
    const dispatch = useDispatch()
    const history = useHistory()
    const fetchingAppData = useRef(false)
    useSocketSubscribe(socket)

    const fetchOrganisations = () => {
        if ((/* loadingOrganization === false && */ organizations?.length === 0) || organizationsError === true) {
            const options = {
                onError: (message: string) => toastMessages.error(message ?? 'Failed to fetch organizations, Please refresh the app'),
                config: {
                    headers: {
                        Authorization: `Bearer ${authState.accessToken.accessToken}`
                    }
                }
            }
            return getOrganizations(options)
        }
        return null
    }

    const fetchPathlabs = () => {
        if ((/* loadingPathLabs === false && */ pathLabs?.length === 0 && (getRole?.includes('Collaborative Order Agreement') || getRole?.includes('PathLab User'))) || pathLabsError === true) {
            const options = {
                onError: (message: string) => toastMessages.error(message ?? 'Failed to fetch path labs, Please refresh the app'),
                config: {
                    headers: {
                        Authorization: `Bearer ${authState.accessToken.accessToken}`
                    }
                }
            }
            return getPathLabUsers(options)
        }
        return null
    }

    const fetchUserProfile = () => {
        if ((/* loadingProfile === false && */ Object.keys(profile).length === 0) || loadingProfileError === true || (authState?.accessToken?.claims?.Profile?.email !== profile?.email)) {
            const options = {
                accessToken: authState?.accessToken?.accessToken,
                onError: (message: string) => {
                    toastMessages.error(message)
                    oktaAuth.signOut()
                    dispatch({ type: LOGOUT, payload: true });
                }
            }
            return fetchProfile(options)
        }
        return null
    }

    const fetchSuffix = () => {
        if ((/* fetchingSuffix === false && */ suffixData.length === 0) || errorSuffix === true) {
            return getSuffix({
                data: {
                    token: authState?.accessToken?.accessToken
                },
                onError: () => {
                    // alert('error occured')
                }
            })
        }
        return null
    }

    const fetchSpecialty = () => {
        if ((/* fetchingSpeciality === false &&  */specialityData.length === 0) || errorSpeciality === true) {
            return getSpeciality({
                data: {
                    token: authState?.accessToken?.accessToken
                },
                onError: () => {
                    // alert('error occured')
                }
            })
        }
        return null
    }

    const getNotificationMedium = () => {
        const options = {
            config: {
                headers: {
                    Authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            }
        }
        return fetchNotificationMedium(options)
    }

    const getOrganizationNotificationMedium = () => {
        const options = {
            config: {
                headers: {
                    Authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            }
        }
        return fetchOrganizationNotificationMedium(options)
    }

    const getPersonalNotificationMedium = () => {
        const options = {
            config: {
                headers: {
                    Authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            }
        }
        return fetchUserNotificationMedium(options)
    }

    const getNotificationType = () => {
        const options = {
            config: {
                headers: {
                    Authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            }
        }
        return fetchNotificationType(options)
    }

    const getAllUserNotificationType = () => {
        const options = {
            config: {
                headers: {
                    Authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            }
        }
        return fetchUserNotificationType(options)
    }

    const fetchUnreadCount = () => {
        if (fetchingUnreadCount || fetchedUnReadCount) return
        const options = {
            config: {
                headers: {
                    Authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            }
        }

        return getUnreadCount(options)
    }

    function handleNotification() {
        const isPathLabOrCoa = getRole?.includes('PathLab User') || getRole?.includes('Collaborative Order Agreement');
        const isOfficeManager = getRole?.includes('Office Manager');

        if (syncSuccess === false) return
        if (location?.pathname == '/profile-completion') return
        if ((isPathLabOrCoa) && !loadingPathLabs && pathLabs?.length == 0) {
            dispatch(NotificationActions.showNotification({
                type: 'error',
                title: 'Login',
                hideClose: true,
                message: `Your user account has not been associated with an Pathology Lab. Please reach out to Castle's Clinical Services. Thank you for your patience.`,
                customFunction() {
                    // Logout user from OKTA
                    oktaAuth.signOut()
                    dispatch({ type: LOGOUT, payload: true });
                },
                buttons: [
                    {
                        onClick: () => {
                            oktaAuth.signOut()
                            dispatch({ type: LOGOUT, payload: true });
                            dispatch(NotificationActions.hideNotifications())
                        },
                        title: 'OK',
                        type: "primary",
                    }
                ]
            }))
            return
        }
        if (!loadingOrganization && organizations?.filter((organization: any) => organization?.isApproved)?.length == 0 && isOfficeManager) {
            dispatch(NotificationActions.showNotification({
                type: 'error',
                title: 'Login',
                hideClose: true,
                message: `Your user account has not be associated with any approved organization. Please reach out to Castle's Clinical Services. Thank you for your patience.`,
                customFunction() {
                    // Sing out user from OKTA as well
                    oktaAuth.signOut()
                    dispatch({ type: LOGOUT, payload: true });
                    // Sing out user from OKTA as well
                    oktaAuth.signOut()
                    dispatch({ type: LOGOUT, payload: true });
                },
                buttons: [
                    {
                        onClick: () => {
                            oktaAuth.signOut()
                            dispatch({ type: LOGOUT, payload: true });
                            dispatch(NotificationActions.hideNotifications())
                        },
                        title: 'OK',
                        type: "primary",
                    }
                ]
            }))
            return
        }
        if (profile?.acceptTerms === false) {
            dispatch(NotificationActions.showNotification({
                type: 'success',
                title: 'Terms & Conditions',
                message: '',
                hideClose: true,
                Component: TermsAndConditionsPopup,
                buttons: []
            }))
            return
        }
    }

    const fetchAppData = () => {
        let promises = []
        if (fetchingAppData.current) return
        if (authState?.accessToken !== null) {
            fetchingAppData.current = true
            promises.push(fetchOrganisations())
            promises.push(fetchPathlabs())
            promises.push(fetchUserProfile())
            promises.push(fetchSuffix())
            promises.push(fetchSpecialty())
            promises.push(getNotificationMedium())
            promises.push(getOrganizationNotificationMedium())
            promises.push(getPersonalNotificationMedium())
            promises.push(getNotificationType())
            promises.push(getAllUserNotificationType())
            promises.push(fetchUnreadCount())
        }

        Promise.allSettled(promises)
            .then((results) => {
                fetchingAppData.current = false
                handleNotification()
            })
            .catch(error => {
                fetchingAppData.current = false
                console.log(error)
            })
    }




    useEffect(() => {

        if (sessionDestroyed) return

        if (profile && Object.keys(profile).length > 0 && !!profile.roles === false && syncSuccess) return history.replace('/unauthorized')

        if (profile && Object.keys(profile).length > 0 && profile.status === 'Blocked' && syncSuccess) return history.replace('/unauthorized')

        if (getRole && getRole?.includes("Staff")) return history.replace('/unauthorized')

        if (getRole && (getRole?.includes('Collaborative Order Agreement') || (getRole?.includes('Physician') && getRole?.length === 1) || (getRole?.includes('PathLab User'))) && location?.pathname?.includes('notifications/settings/organization-settings')) return history.replace('/unauthorized')

        if (authState?.accessToken != null) {
            fetchAppData()

            if (profile && Object.keys(profile)?.length > 0 /* && organizations?.length > 0 */ && shouldFetch || (syncError)) {
                const handleResync = () => {
                    const options = {
                        onError: (message: string) => toastMessages.error(message ?? 'Failed to fetch organizations, Please refresh the app'),
                        config: {
                            headers: {
                                Authorization: `Bearer ${authState.accessToken.accessToken}`
                            }
                        }
                    }
                    const pathLabOptions = {
                        onError: (message: string) => toastMessages.error(message ?? 'Failed to fetch path labs, Please refresh the app'),
                        config: {
                            headers: {
                                Authorization: `Bearer ${authState.accessToken.accessToken}`
                            }
                        }
                    }
                    const meOptions = {
                        accessToken: authState?.accessToken?.accessToken,
                        onError: (message: string) => {
                            toastMessages.error(message)
                            oktaAuth.signOut()
                            dispatch({ type: LOGOUT, payload: true });
                        }
                    }
                    getOrganizations(options)
                    fetchProfile(meOptions)
                    if (getRole.includes('Collaborative Order Agreement') || getRole.includes('PathLab User'))
                        getPathLabUsers(pathLabOptions)
                }
                const syncOptions = {
                    onError: () => {

                    },
                    onResync: handleResync,
                    config: {
                        headers: {
                            Authorization: `Bearer ${authState?.accessToken?.accessToken}`
                        }
                    },
                    userId: profile.id
                }
                fetchSync(syncOptions)
            }
        }
        if ((fetchingStateAndCity === false && stateAndCityData.length === 0) || errorStateAndCity === true) {
            getStateAndCity({})
        }
        if ((fetchActiveTests === false && activeTests.length === 0) || fetchingActiveTestsError === true) {
            fetchActiveTests({
                onError: (message: string) => {
                    toastMessages.error(message)
                }
            })
        }



    }, [/* authState, profile, sessionDestroyed */ profile, organizations.length, loadingProfileError, syncSuccess])
    // return children

    useEffect(() => {


        // if (authState?.accessToken !== null) {
        //     socket?.on('disconnect', (reason: any) => {
        //         console.log(`disconnected because of ${reason}`)
        //     })

        //     socket?.on('notification', (data: ApiNotificationData) => {
        //         console.log('NOTIFICATION ON EVENT')
        //         let temp: ApiNotificationData[] = [data, ...notificationsData]
        //         const NotificationsList = new NotificationsListService(temp);

        //         dispatch({
        //             type: FETCH_NOTIFICATIONS_SUCCESS,
        //             payload: {
        //                 notificationsList: NotificationsList.getNotificationsList(),
        //                 notificationsData: temp
        //             },
        //         });

        //         // const unReadCount = [data].filter(
        //         //     (notification: ApiNotificationData) => notification.isRead == false
        //         // ).length;

        //         dispatch({
        //             type: UPDATE_NOTIFICATION_UNREAD_COUNT,
        //             payload: unReadCount + 1
        //         });
        //     })
        // }

        // return () => {
        //     socket?.off('connection')
        //     socket?.off('disconnect')
        // }
    }, [socket])

    if (authState?.accessToken != null) {
        return children
    }
    return <Redirect to={'/'} />
}


const mapStateToProps = (state: any): any => ({
    loadingOrganization: state.Organizations.fetching,
    organizationsError: state.Organizations.error,
    organizations: state.Organizations.data,
    loadingPathLabs: state.PathLab.fetching,
    pathLabsError: state.PathLab.error,
    pathLabs: state.PathLab.data,
    loadingProfile: state.Profile.fetching,
    loadingProfileError: state.Profile.error,
    profile: state.Profile.profile,
    fetchingSuffix: state.Suffix.fetchingSuffix,
    errorSuffix: state.Suffix.errorSuffix,
    suffixData: state.Suffix.suffix,
    fetchingSpeciality: state.Speciality.fetchingSpeciality,
    errorSpeciality: state.Speciality.errorSpeciality,
    specialityData: state.Speciality.speciality,
    fetchingStateAndCity: state.StateAndCity.fetching,
    errorStateAndCity: state.StateAndCity.error,
    stateAndCityData: state.StateAndCity.data,
    loadingDashboard: state.Dashboard.fetching,
    loadingDashboardError: state.Dashboard.error,
    dashboardData: state.Dashboard.data,
    shouldFetch: state.Sync.shouldFetch,
    syncError: state.Sync.syncError,
    syncSuccess: state.Sync.syncSuccess,
    fetchingActiveTests: state.ActiveTests.fetching,
    fetchingActiveTestsError: state.ActiveTests.error,
    activeTests: state.ActiveTests.activeTests,
    fetching: state.UserNotifications.fetching,
    error: state.UserNotifications.error,
    fetchingUnreadCount: state.UserNotifications.fetchingUnReadCount,
    fetchingUnreadCountError: state.UserNotifications.fetchingUnReadCountError,
    fetchingUnreadCountSuccess: state.UserNotifications.fetchingUnReadCountSuccess,
    fetchedUnReadCount: state.UserNotifications.fetchedUnReadCount
})

const mapDispatchToProps = {
    getOrganizations: OrganizationActions.fetchOrganizationsWithoutUsers,
    getPathLabUsers: PathLabActions.fetchPathlabs,
    getStateAndCity: StateAndCityActions.fetchStateAndCity,
    getSpeciality: SuffixAndSpecialityActions.fetchSpeciality,
    getSuffix: SuffixAndSpecialityActions.fetchSuffix,
    fetchProfile: ProfileActions.fetchprofile,
    fetchDashboard: DashboardActions.fetchDashboard,
    fetchSync: SyncActions.fetchSync,
    fetchActiveTests: ActiveTestsActions.fetchActiveTests,
    fetchNotificationMedium: UserNotificationActions.fetchNotificationMedium,
    fetchOrganizationNotificationMedium: UserNotificationActions.fetchOrganizationNotificationMedium,
    fetchUserNotificationMedium: UserNotificationActions.fetchUserNotificationMedium,
    fetchUserNotificationType: UserNotificationActions.fetchUserNotificationType,
    fetchNotificationType: UserNotificationActions.fetchNotificationType,
    getUnreadCount: UserNotificationActions.getUnreadCount
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
